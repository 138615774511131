<template>
  <div>
    <label class="col-form-label col-form-label--accent" v-if="title">{{ title }}</label>
    <div>
      <b-form-row>
        <b-col cols="6">
          <field-text
            :disabled="readOnly"
            :fieldId="ids.taxCode"
            placeholder="Codice Fiscale"
            title="Codice Fiscale"
            :state="state.taxCode"
            v-model="taxCode"
          />
        </b-col>
        <b-col cols="6">
          <field-text
            :disabled="readOnly"
            :fieldId="ids.vatNumber"
            placeholder="Partita IVA"
            title="Partita IVA"
            :state="state.vatNumber"
            v-model="vatNumber"
          />
        </b-col>
        <b-col cols="6">
          <field-text
            :disabled="readOnly"
            :fieldId="ids.emailPEC"
            placeholder="PEC"
            title="PEC"
            :state="state.emailPEC"
            v-model="emailPEC"
          />
        </b-col>
        <b-col cols="6" v-if="isCompany">
          <field-text
            :disabled="readOnly"
            :fieldId="ids.sdiCode"
            placeholder="SDI"
            title="SDI"
            :state="state.sdiCode"
            v-model="sdiCode"
          />
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
const FieldText = () => import('@/components/fields/FieldText.vue');

export default {
  name: 'FieldBilling',
  components: {
    FieldText,
  },
  model: {
    prop: 'billing',
    event: 'input',
  },
  props: {
    fieldId: String,
    billing: Object,
    title: String,
    state: Object,
    errorMessage: String,
    readOnly: Boolean,
    isCompany: Boolean,
  },
  computed: {
    failed() {
      return this.errorMessage || 'Il campo è obbligatorio';
    },
    ids() {
      return {
        taxCode: `${this.fieldId}.taxCode`,
        vatNumber: `${this.fieldId}.vatNumber`,
        emailPEC: `${this.fieldId}.invoicePecEmail?.contactData`,
        sdiCode: `${this.fieldId}.sdiCode`,
      };
    },
    taxCode: {
      get() {
        return this.billing.taxCode;
      },
      set(taxCode) {
        this.$emit('input', { ...this.billing, taxCode });
      },
    },
    vatNumber: {
      get() {
        return this.billing.vatNumber;
      },
      set(vatNumber) {
        this.$emit('input', { ...this.billing, vatNumber });
      },
    },
    emailPEC: {
      get() {
        return this.billing.invoicePecEmail?.contactData;
      },
      set(emailPEC) {
        const newPec = { ...this.billing.invoicePecEmail };
        newPec.contactData = emailPEC;
        this.$emit('input', { ...this.billing, invoicePecEmail: newPec });
      },
    },
    sdiCode: {
      get() {
        return this.billing.sdiCode;
      },
      set(sdiCode) {
        this.$emit('input', { ...this.billing, sdiCode });
      },
    },
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
